export const typography = {
  letterSpacings: {
    tight: "-0.005em",
    normal: "0",
    wide: "0.005em",
  },

  lineHeights: {
    none: 1,
    short: 1.2,
    base: 1.69,
  },

  fontWeights: {
    normal: 400,
    bold: 700,
  },

  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },

  fontSizes: {
    xs: "11px",
    sm: "12px",
    md: "13px",
    lg: "15px",
  },
}
