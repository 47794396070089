import PropTypes from "prop-types"
import { ChakraProvider } from "@chakra-ui/react"
import { WagmiConfig, createConfig } from "wagmi"
import { ConnectKitProvider, getDefaultConfig } from "connectkit"
import ReactGA from "react-ga4"
import { theme } from "../utils/theme"
import { config } from "../utils/config"
import "../styles/globals.css"
import { Footer } from "../components/Footer"

const { infuraId } = config

const wagmiConfig = createConfig(
  getDefaultConfig({
    // Required API Keys
    infuraId,
    walletConnectProjectId: process.env.NEXT_PUBLIC_WALLETCONNECT_PROJECT_ID,

    // Required
    appName: "Kinetic Spectrums",

    // Optional
    appDescription:
      "Kinetic Spectrums is a generative collection of dynamic artworks stored on the Ethereum Network.",
    appUrl: "https://kineticspectru.ms/", // your app's url
    appIcon: "https://kineticspectru.ms/favicon.png", // your app's icon, no bigger than 1024x1024px (max. 1MB)
  })
)

ReactGA.initialize("G-HRW21WE8M6")
ReactGA.send("pageview")

export default function App({ Component, pageProps }) {
  return (
    <WagmiConfig config={wagmiConfig}>
      <ConnectKitProvider>
        <ChakraProvider theme={theme}>
          <Component {...pageProps} />
          <Footer />
        </ChakraProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  )
}

App.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.any.isRequired,
}
